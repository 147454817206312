import React from 'react';
import {
    MDBCard,
    MDBCardImage,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardFooter,
    MDBCardGroup
} from 'mdb-react-ui-kit';

export  function Kategori() {
    return (
        <MDBCardGroup>
            <MDBCard>
                <MDBCardImage src='https://ibwfiles.s3.ap-southeast-1.amazonaws.com/omakoci/banner/bannerKategori/1.jpg' alt='...' position='top' />
                <MDBCardBody>
                    <MDBCardTitle>PENDIDIKAN</MDBCardTitle>
                    <MDBCardText>
Anda bisa mengetahui dan merencanakan pendidikan anak dengan kami bekerjasama dengan : Angels & I Daycare (kurikulum terintegrasi) dan ABO Center (Pengembangan anak berdasarkan golongan darah)
                    </MDBCardText>
                </MDBCardBody>
                <MDBCardFooter>
                    <small className='text-muted'>Last updated 3 mins ago</small>
                </MDBCardFooter>
            </MDBCard>

            <MDBCard>
                <MDBCardImage src='https://ibwfiles.s3.ap-southeast-1.amazonaws.com/omakoci/banner/bannerKategori/2.jpg' alt='...' position='top' />
                <MDBCardBody>
                    <MDBCardTitle>SOSIAL</MDBCardTitle>
                    <MDBCardText>
                        Tahun 2023 ini kita bekerjasama dengan Makesense.org untuk mengatasi isu iklim yang semakin berat di dunia.
                    </MDBCardText>
                </MDBCardBody>
                <MDBCardFooter>
                    <small className='text-muted'>Last updated 3 mins ago</small>
                </MDBCardFooter>
            </MDBCard>

            <MDBCard>
                <MDBCardImage src='https://ibwfiles.s3.ap-southeast-1.amazonaws.com/omakoci/banner/bannerKategori/3.jpg' alt='...' position='top' />
                <MDBCardBody>
                    <MDBCardTitle>BISNIS</MDBCardTitle>
                    <MDBCardText>
                    Anda ingin mengembangkan bisnis atau bekerjasama marketing dengan kami. Bisa kontak disini  
                    </MDBCardText>
                </MDBCardBody>
                <MDBCardFooter>
                    <small className='text-muted'>Last updated 3 mins ago</small>
                </MDBCardFooter>
            </MDBCard>
        </MDBCardGroup>
    );
}