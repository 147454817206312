import React from 'react';
import {
    MDBContainer,
    MDBRow,
    MDBCard,
    MDBCol,
    MDBBadge,
    MDBCardBody,
    MDBBtn,
    MDBIcon,
    MDBCardFooter
} from 'mdb-react-ui-kit';
import { Stat } from './stat';

export function Dampak() {
    return (
        <MDBContainer fluid>
            <MDBRow className='justify-content-center'>
                <MDBCol md='10'>
                    <section>
                  <Stat/>
                    </section>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}


