
import React, { Component, useState, useEffect } from 'react';
import { MDBContainer, MDBTypography, MDBCardGroup, MDBCardHeader, MDBAnimation, MDBCardTitle, MDBCardText, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBIcon, MDBView, MDBBtn } from "mdb-react-ui-kit";
import { Text } from './Text';
import { QuoteList } from './QuoteListAdaJudul';
import { Kategori } from './kategori';


export function Home(props)  {
    const displayName = Home.name;
    const[isform, setisform] = useState(false);
    const[kata, setkata] = useState("member");
    const[warna, setwarna] = useState("text-warning");
    const listkata = ["poin","member","tantangan","kerjasama","survey","event"]
    const listkata2 = ["poverty", "gender", "peace", "cleanwater", "education", "hunger"]
    const listwarna = ["bg-warning", "bg-dark", "bg-success", "bg-secondary", "bg-info", "bg-danger", "bg-warning", "bg-dark", "bg-success", "bg-secondary", "bg-info", "bg-danger"]
    let p;
    let cursor;
    cursor = 0;
    p = 0;
    useEffect(() => {
        //  console.log(dataAksi)

        setInterval(function () {
            p++
            if (Math.trunc(p / 10) - (p / 10) == 0) {
                cursor++
                setkata(listkata[cursor]);
                setwarna(listwarna[cursor] + " text-white px-2 py-1 mx-0");

                if ((cursor + 1) == listkata.length) cursor = 0;
            }

        }, 1000);
    }, []);

    return (
      <div>

               <div className='my-3 text-center'>

                <MDBTypography tag='h1' className='mt-3'><MDBIcon fab icon="whatsapp" className="text-success" /> <a href={"https://wa.me/6285646555420?text=" + kata}> wa.me/6285 646 555 420<span className="text-muted"> ? text=</span></a>
                    <MDBAnimation
                        infinite
                        tag='span'
                        animation='pulse'
                        className={warna}
                        start='onLoad'
                        duration={1500}
                    >{kata}</MDBAnimation>

                </MDBTypography>
                <hr />
              <p>  <MDBTypography tag='h3'  className="text-warning"> <Text tid="" />Kolaborasi Ciamik   </MDBTypography></p>
        
            </div>
      

            <MDBContainer>
                <Kategori />
            </MDBContainer>
            <MDBRow className='my-3'>
                <MDBAnimation
                    infinite
                    tag='i'
                    animation='fade-out-right'
                    className='fa-solid fa-people-group fa-3x'
                    start='onLoad'
                    duration={10000}
                > .. go .... !   </MDBAnimation>
            </MDBRow>
            <MDBContainer>
                <QuoteList />
            </MDBContainer>
            <MDBContainer>

                <MDBRow className="">

                    <MDBCardGroup>
                        <MDBCard className='bg-primary text-white'>

                            <MDBCardBody>
                                <MDBCardTitle >    <MDBTypography tag='h3' className='mb-0'>
                                    [Outreach]
                                    <br /> <MDBTypography tag='small' className='text-black text-black-40'>
                                        Gabung Yuk
                                    </MDBTypography>
                                </MDBTypography>
                                </MDBCardTitle>
                                <MDBCardText>
                                    Mari bergabung menjadi penggerak kegiatan sosial.
                                    Bersama kita bisa membuat dunia jadi lebih baik

                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>


                        <MDBCard className='bg-success  text-white'>

                            <MDBCardBody>
                                <MDBCardTitle className="text-white">    <MDBTypography tag='h3' className='mb-0'>
                                    [Manage]
                                    <br /> <MDBTypography tag='small' className='text-black  text-black-40'>
                                        Lakukan Aksi
                                    </MDBTypography>
                                </MDBTypography>
                                </MDBCardTitle>
                                <MDBCardText>
                                    Program Pendidikan, Sosial & Bisnis bisa lebih dahsyat jika dikerjakan bersama

                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>

                        <MDBCard className='bg-warning text-white'>

                            <MDBCardBody>
                                <MDBCardTitle className="text-white">    <MDBTypography tag='h3' className='mb-0'>
                                    [Assess]
                                    <br /> <MDBTypography tag='small' className='text-black text-black-40'>
                                        Rayakan Dampak
                                    </MDBTypography>
                                </MDBTypography>
                                </MDBCardTitle>
                                <MDBCardText>
                                    Dampak yang dapat diukur dan dipahami dapat mendorong kita tumbuh, dan pemerintah ikut berperan.
                                </MDBCardText>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCardGroup>

                </MDBRow>
   
            </MDBContainer>
        </div>
    );
  
}
