import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import { Dampak } from "./components/dampak";
const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/member',
    element: <Counter />
    },
    {
        path: '/partner',
        element: <FetchData/>
    },
  {
    path: '/dampak',
    element: <Dampak />
  }
];

export default AppRoutes;
